import React, { useContext, useEffect, useRef, useState } from "react"
import { Link, useRouter } from "next-translate-routes"
import Image from "next/image"
import styled from "styled-components"
import ValvestLogo from "@/public/logo_valvest.svg"
import translate from "@/utils/translation"
import LanguageDropdown from "../language/languageDropdown"
import AccountDropdown from "../accountMenu/accountDropdown"
import NotificationDropdown from "../notifications/notificationDropdown"
import JumboLearn from "./JumboDropdown/JumboLearn"
import JumboAbout from "./JumboDropdown/JumboAbout"
import { Context } from "@/context/state"

// Fixed wrapper styled component
const FixedMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  background-color: white;
`

const MenuDesktop = ({ session }) => {
  const [isJumboLearnActive, setIsJumboLearnActive] = useState(false)
  const [IsIsJumboAboutActive, setIsJumboAboutActive] = useState(false)

  const router = useRouter()

  function getLinkStyle(path) {
    return {
      color: router.pathname === path ? "rgb(251, 133, 0)" : "inherit",
    }
  }

  const [hasScrolled, setHasScrolled] = useState(false)

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setHasScrolled(true)
      } else {
        setHasScrolled(false)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const { state, dispatch } = useContext(Context)

  // Function to generate avatar properties
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "orange",
        fontSize: "1rem",
      },
      children: `${name?.split(" ")[0][0]}${name?.split(" ")?.[1]?.[0] || ""}`,
    }
  }

  // Function to get link styles based on current path
  function getLinkStyle(path) {
    return {
      color: router.pathname === path ? "rgb(251, 133, 0)" : "inherit",
    }
  }

  return (
    <FixedMenuWrapper
      style={
        router.pathname === "/" && !hasScrolled
          ? {
              boxShadow: "none",
              transform: "translateY(0)",
              transition: "transform 0.3s ease-in-out",
            }
          : !hasScrolled
          ? {
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
              transform: "translateY(0)",
              transition: "transform 0.3s ease-in-out",
            }
          : state?.showMenu
          ? {
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
              transform: state?.showMenu
                ? "translateY(0)"
                : "translateY(-100%)",
              transition: "transform 0.3s ease-in-out",
            }
          : {
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
              transform: state?.showMenu
                ? "translateY(0)"
                : "translateY(-100%)",
              transition: "transform 0.3s ease-in-out",
            }
      }
    >
      <Menu className="container inner d-none d-lg-block">
        <div className="row align-items-center">
          <div className="col-4 col-md-2 col-xl-2">
            <Link href="/" className="clickable">
              <Image
                src={ValvestLogo}
                alt="valvest Logo"
                className="clickable"
                as="a"
                priority
                width={180}
              />
            </Link>
          </div>
          <div className="col-4 col-md-7 nav-items d-flex">
            <span className="nav-item clickable">
              <Link href={"/listings"} style={getLinkStyle("/listings")}>
                {translate("menu_listings")}
              </Link>
            </span>
            <span
              className="nav-item clickable"
              onMouseEnter={() => setIsJumboAboutActive(true)}
              onMouseLeave={() => setIsJumboAboutActive(false)}
            >
              <Link href="/about-us" style={getLinkStyle("/about-us")}>
                {translate("menu_about")}
              </Link>
              <JumboAbout
                isVisible={IsIsJumboAboutActive}
                setIsJumboAboutActive={setIsJumboAboutActive}
              />
            </span>
            <span
              className="nav-item clickable"
              onMouseEnter={() => setIsJumboLearnActive(true)}
              onMouseLeave={() => setIsJumboLearnActive(false)}
            >
              <Link
                href="/blog"
                style={getLinkStyle("/blog")}
                onMouseEnter={() => setIsJumboLearnActive(true)}
              >
                {translate("account_menu_learn")}
              </Link>
              <JumboLearn
                isVisible={isJumboLearnActive}
                setIsJumboLearnActive={setIsJumboLearnActive}
              />
            </span>
          </div>
          {!session?.uid ? (
            <div className="col-4 col-md-3 nav-items d-none d-lg-flex justify-content-end">
              <LanguageDropdown />
              <span className="nav-item p-0 clickable">
                <Link
                  locale={router.locale}
                  href="/auth/login"
                  className="login"
                >
                  {translate("menu_login")}
                </Link>
              </span>
              <span className="nav-item p-0 clickable">
                <Link
                  locale={router.locale}
                  href="/auth/register"
                  style={{
                    padding: "0.65rem 1.125rem",
                    borderRadius: "25px",
                  }}
                  className=" btn-lg btn-primary text-white clickable me-3"
                >
                  {translate("menu_register")}
                </Link>
              </span>
            </div>
          ) : (
            <div className="col-4 col-md-3 d-none d-lg-flex nav-items justify-content-end">
              {/* <NotificationDropdown /> */}
              <AccountDropdown session={session} />
            </div>
          )}
        </div>
      </Menu>
    </FixedMenuWrapper>
  )
}

export default MenuDesktop

// Styled Menu component without fixed positioning
const Menu = styled.div`
  .login {
    border: 1px solid #333;
    border-radius: 25px;
    :hover {
      border: 1px solid #8a8a8a;
    }
    padding: 0.65rem 1.125rem;
    color: #333;
    text-decoration: none;
  }

  .globe {
    margin: auto 0;
  }

  .nav-items {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    .nav-item {
      font-size: 1rem;
      a {
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: rgb(251, 133, 0);
        }
      }
    }
  }
`
