import { Link, useRouter } from "next-translate-routes"
import Image from "next/image"
import React, { useState, useEffect, useRef, useContext } from "react"
import ValvestLogo from "@/public/logo_valvest.svg"
import ValvestFavicon from "@/public/favicon_valvest.svg"
import MobileLanguage from "../language/mobileLanguage"
import MobileMenuSlideout from "./mobileMenuSlideout"
import { Avatar, Button } from "@mui/material"
import styled from "styled-components"
import AccountMobile from "../accountMenu/accountMobile"
import { RiQuestionnaireFill } from "react-icons/ri"
import translate from "@/utils/translation"
import LanguageDropdown from "../language/languageDropdown"
import NotificationDropdown from "../notifications/notificationDropdown"
import { Context } from "@/context/state"

const MenuMobile = ({ session }) => {
  const router = useRouter()
  const [showMobile, setMobile] = useState(false)
  const [showProfile, setProfile] = useState(false)

  const lastScrollY = useRef(0)
  const ticking = useRef(false)
  const scrollThreshold = 10

  const { state, dispatch } = useContext(Context)

  const showMenu = state?.showMenu

  const [hasScrolled, setHasScrolled] = useState(false)

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setHasScrolled(true)
      } else {
        setHasScrolled(false)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  // Function to generate avatar properties
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "orange",
        fontSize: "1rem",
      },
      children: `${name?.split(" ")[0][0]}${name?.split(" ")?.[1]?.[0] || ""}`,
    }
  }

  // Function to get link styles based on current path
  function getLinkStyle(path) {
    return {
      color: router.pathname === path ? "rgb(251, 133, 0)" : "inherit",
    }
  }
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY

      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          const scrollDelta = currentScrollY - lastScrollY.current

          if (scrollDelta > scrollThreshold && currentScrollY > 200) {
            // Scrolling down and past 300px
            if (showMenu) dispatch({ type: "SET_SHOW_MENU", payload: false })
          } else if (scrollDelta < -scrollThreshold) {
            // Scrolling up
            if (!showMenu) dispatch({ type: "SET_SHOW_MENU", payload: true })
          }

          lastScrollY.current = currentScrollY
          ticking.current = false
        })

        ticking.current = true
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [dispatch, showMenu])

  useEffect(() => {
    const handleRouteChange = () => {
      dispatch({ type: "SET_SHOW_MENU", payload: true })
    }

    router.events.on("routeChangeComplete", handleRouteChange)

    // Cleanup the event listener on unmount
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events, dispatch])

  return (
    <Menu
      className="d-flex row d-lg-none container ps-2 pe-2 p-md-0 pt-2"
      style={
        router.pathname === "/" && !hasScrolled
          ? {
              boxShadow: "none",
              transform: "translateY(0)",
              transition: "transform 0.3s ease-in-out",
            }
          : !hasScrolled
          ? {
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
              transform: "translateY(0)",
              transition: "transform 0.3s ease-in-out",
            }
          : state?.showMenu
          ? {
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
              transform: state?.showMenu
                ? "translateY(0)"
                : "translateY(-100%)",
              transition: "transform 0.3s ease-in-out",
            }
          : {
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
              transform: state?.showMenu
                ? "translateY(0)"
                : "translateY(-100%)",
              transition: "transform 0.3s ease-in-out",
            }
      }
    >
      <div className="col-1 d-lg-none d-flex nav-items justify-content-start">
        <div onClick={() => setMobile(!showMobile)} className="mobile-menu">
          <svg viewBox="0 0 100 100" width="20" height="20">
            <rect width="100" height="8"></rect>
            <rect y="30" width="100" height="8"></rect>
            <rect y="60" width="100" height="8"></rect>
          </svg>
        </div>
      </div>
      <MobileMenuSlideout
        showMobile={showMobile}
        setMobile={setMobile}
        session={session}
      />
      <AccountMobile
        showMobile={showProfile}
        setMobile={setProfile}
        session={session}
      />
      <div className="col-7 pb-1">
        <Link href="/" className="clickable">
          <Image
            src={ValvestLogo}
            alt="Valvest Logo"
            className="clickable d-none d-md-block"
            as="a"
            style={{ marginTop: "3px" }}
            priority
            width={160}
          />
          <Image
            src={ValvestFavicon}
            alt="Valvest Favicon"
            className="clickable d-md-none d-block ms-2"
            as="a"
            style={{ marginTop: "5px" }}
            priority
            width={40}
          />
        </Link>
      </div>
      {session?.profile ? (
        <div className="col-2 offset-2 d-flex justify-content-end pe-1">
          <div
            role="button"
            className="avatar"
            onClick={() => {
              setProfile(true)
            }}
          >
            <Avatar
              style={{
                height: "35px",
                width: "35px",
              }}
              {...stringAvatar(
                `${session.profile.firstName}`.toUpperCase() +
                  ` ${session.profile.lastName || ""}`.toUpperCase()
              )}
            />
          </div>
        </div>
      ) : (
        <div className="col-4 d-flex justify-content-end pe-0">
          <LanguageDropdown />
          {router.pathname.includes("/login") ? (
            <Link href="/auth/register" className="clickable mt-auto mb-auto">
              <Button
                variant="outlined"
                className="rounded-pill login"
                color="primary"
              >
                {translate("menu_register")}
              </Button>
            </Link>
          ) : (
            <Link href="/auth/login" className="clickable mt-auto mb-auto">
              <Button
                variant="outlined"
                className="rounded-pill login"
                color="primary"
              >
                {translate("menu_login")}
              </Button>
            </Link>
          )}
        </div>
      )}
    </Menu>
  )
}

export default MenuMobile

const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  padding: 0.25rem 1rem !important;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9;
  margin: 0 auto;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
  min-height: 65px;

  .avatar {
    margin-top: 0.5rem;
    border-radius: 50px;
    border: 1px solid rgba(18, 20, 23, 0.1) !important;
    padding: 3px;
    width: 43px;
    height: 43px;

    .MuiAvatar-root {
      /* Additional styles if needed */
    }
  }

  .globe,
  .country-name {
    color: #777;
    margin: auto 0 auto 0;
  }

  .country {
    padding: 0 !important;
    margin-right: 1rem !important;
  }

  .login {
    margin-top: 0.5rem;
    padding: 0.5rem 1.5rem;
    height: 40px;
    margin: auto 0.25rem auto 0;
  }

  .help {
    margin-top: 0.9rem;
    margin-right: 1.5rem;

    svg {
      color: rgba(18, 20, 23, 0.75);

      :hover {
        color: rgba(18, 20, 23, 1) !important;
      }
    }
  }

  .mobile-menu {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    rect {
      fill: #000; /* Adjust color as needed */
      transition: fill 0.3s ease-in-out;
    }

    &:hover rect {
      fill: rgba(251, 133, 0, 1); /* Example hover color */
    }
  }

  /* Optional: Add padding-top to compensate for fixed menu height */
  /* You can adjust the value based on the actual height of the menu */
  body {
    padding-top: 60px;
  }

  /* Responsive adjustments if needed */
  @media (max-width: 768px) {
    /* Example: Adjust padding on smaller screens */
    padding: 0.2rem 0.8rem !important;
  }
`
